.overlay-play-button {
    /* Used to position the overlay */
    position: relative;
}

.overlay-play-button__overlay {
    /* Position */
    left: 0;
    position: absolute;
    top: 0;

    /* Take full size */
    height: 100%;
    width: 100%;

    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;
}

.overlay-play-button__play {
    border: 0.25rem solid #fff;
    border-radius: 9999px;
    height: 3rem;
    width: 3rem;

    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;
}

.triangle--sm {
    --triangle-size: 0.5rem;
}
.triangle--r {
    border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) #d1d5db;
    border-width: var(--triangle-size) 0 var(--triangle-size) 1rem;
}
.triangle {
    border-style: solid;
    height: 0;
    width: 0;
}